<script setup>
import { ref, onBeforeMount } from 'vue'
import { getKeyGuest } from '@/api/2023/index.js'

const iscArr = ref([])

const getInfo = () => {
  getKeyGuest().then((res) => {
    iscArr.value = res.data.data.slice(0, 9)
  })
}

onBeforeMount(async () => {
  await getInfo()
})

function toMore() {
  const path = `${window.location.origin}/new/2023/guest.html`
  window.open(path)
}
</script>
<template>
  <div class="isc">
    <div class="iscTitle">ISC智库</div>
    <ul class="iscList">
      <li v-for="item in iscArr" :key="item.name" class="iscListLi">
        <img :src="item.avatar" alt="" />
        <p class="iscListLiName">{{ item.name }}</p>
        <span v-for="i in item.title" :key="i" class="iscListLiDesc">
          {{ i }}
        </span>
      </li>
    </ul>
    <img
      class="iscMore"
      @click="toMore"
      src="https://p0.ssl.qhimg.com/t014dd2729b5fb029e9.png"
      alt="查看更多"
    />
  </div>
</template>
<style lang="scss" scoped>
.isc {
  background: #e9f1ff;
  padding: 40px 24px 0;
  &Title {
    position: relative;
    background: url('https://p3.ssl.qhimg.com/t01dfa26c934332d886.png') no-repeat;
    background-size: 136px 32px;
    text-align: center;
    margin: auto;
    margin-bottom: 32px;
    width: 136px;
    height: 32px;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 32px;
    img {
      width: 100%;
    }
  }
  &List {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 328px;
    display: flex;
    flex-wrap: wrap;
    &Li {
      text-align: center;
      list-style: none;
      width: 80px;
      height: 143px;
      margin-right: 44px;
      margin-bottom: 24px;
      img {
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
      }
      &Name {
        font-weight: bold;
        font-size: 15px;
        color: #222222;
        margin: 10px 0 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &Desc {
        display: inline-block;
        width: 80px;
        font-size: 10px;
        color: #222222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &Li:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  &More {
    display: block;
    width: 130px;
    height: 36px;
    margin: 10px auto 0;
  }
}
</style>
