<script setup>
import { ref } from 'vue'
const coreObj = defineProps(['item'])
const coreItem = ref(coreObj.item)
function toRouter(path) {
  window.open(path)
}
</script>
<template>
  <div class="homePageCore">
    <div v-if="coreItem.video || coreItem.aImg" class="homePageCoreMask"></div>
    <video
      v-if="coreItem.video"
      class="homePageCoreVideo homePageCoreBgc"
      :poster="coreItem.poster"
      autoplay
      muted
      loop
    >
      <source :src="coreItem.video" type="video/mp4" />
      您的浏览器不支持 video 标签。
    </video>
    <img
      v-else
      class="homePageCoreBgc"
      src="https://p1.ssl.qhimg.com/t01fb568d31c37b34c4.png"
      alt="背景图"
    />
    <div class="homePageNav">
      <div class="homePageNavText">
        <p class="homePageNavTextTitle">
          <span>{{ coreItem.title }}</span>
        </p>
        <p v-if="coreItem.tag">
          <span v-for="(i, j) in coreItem.tag" :key="j" class="homePageNavTextTag">{{ i }}</span>
        </p>
        <p class="homePageNavTextDesc">{{ coreItem.desc }}</p>
        <p v-if="coreItem.outSide" class="homePageNavOut">
          <span
            class="homePageNavOutText"
            v-for="item in coreItem.outSide"
            :key="item"
            @click="toRouter(item.router)"
          >
            <img v-if="item.logo" :src="item.logo" class="homePageNavOutImg" />
            {{ item.text }}
          </span>
        </p>
        <img
          v-if="coreItem.moreUrl"
          class="homePageNavMore"
          src="https://p1.ssl.qhimg.com/t0154c831723dfb8c4f.png"
          alt="立即前往"
          @click="toRouter(coreItem.moreUrl)"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.homePageCore {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  &Video {
    width: 100%;
  }
  &Mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
}
.home {
  &PageCore {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &Bgc {
      width: 100%;
      // height: calc(100vh - 64px);
    }
  }
  &PageNav {
    z-index: 2;
    display: flex;
    align-items: center;
    position: absolute;
    top: 202px;
    top: 50%;
    left: 50%;
    // transform: translateX(-50%);
    transform: translate(-50%, -50%);
    &More {
      cursor: pointer;
      display: inline-block;
      width: 246px;
      height: 81px;
      margin: auto;
    }
    &Text {
      position: relative;
      width: 800px;
      text-align: center;
    }
    &TextTitle {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // height: 45px;
      margin: 0;
      span {
        font-weight: bold;
        font-size: 80px;
        margin-right: 20px;
        font-family: TypeRegular;
        color: #ffffff;
      }
      img {
        width: 104px;
        height: 32px;
      }
    }
    &TextDesc {
      text-align: justify;
      margin: 36px 0;
      // font-weight: bold;
      font-size: 28px;
      color: #ffffff;
      line-height: 42px;
    }
    &TextTag {
      background: #2c5adbcc;
      border-radius: 2px;
      color: #ffffff;
      padding: 5px 12px;
      font-weight: bold;
      font-size: 16px;
      margin-right: 12px;
    }
    &Out {
      text-align: center;
      margin-top: 20px;
      &Text {
        cursor: pointer;
        display: inline-block;
        background: #003a29cc;
        border: 1px solid #00ab7a;
        border-radius: 2px;
        font-weight: bold;
        font-size: 16px;
        color: #4ad3a4;
        margin-left: 16px;
        padding: 9px 16px;
        &:hover {
          background: #00ab7a;
          border: 1px solid #00ab7a;
          color: #ffffff;
          .homePageNavOutImg {
            filter: drop-shadow(1000px 0 0);
            transform: translate(-1000px);
          }
        }
      }
      &Img {
        display: inline-block !important;
        width: 24px;
        height: 24px;
        padding: 0;
      }
    }
    &TextMore {
      display: flex;
      font-weight: bold;
      font-size: 16px;
      color: #00ab7a;
      margin-top: 24px;
      cursor: pointer;
      img {
        margin-left: 9px;
      }
    }
  }
}
</style>
