<script setup>
import HomeWeb from '@/components/index/home/index.vue'
import HomeMobile from '@/components/index/homeMobile/index.vue'
// import Computer from '@/components/frontpage/computer/index.vue'
// import CellPhone from '@/components/frontpage/cellPhone/index.vue'

const IS_H5 = /Android|webOS|iPhone|iPad|IEMobile|Opera Mini/i.test(navigator.userAgent)
</script>

<template>
  <!-- /* 判读是从哪个入口进入 */ -->
  <!-- <CellPhone v-if="IS_H5"></CellPhone>
  <Computer v-else></Computer> -->
  <HomeMobile v-if="IS_H5" />
  <HomeWeb v-else />
</template>

<style scoped>
@font-face {
  font-family: 'TypeRegular';
  src: url('@/fontFamily/360shouhuType-Regular.otf');
}
</style>
