<script setup>
import {onBeforeMount, ref} from 'vue'
import {getRecruit} from '@/api/2023/index'
// import { ISC_URL } from '@/mock/header.js'
const logo = 'https://s4.ssl.qhres2.com/static/06be23cb0e529612.svg'
const current = ref(['home'])
const isOpenScroll = ref(false)
const menu = ref([]) // 将 menu 定义为 ref，以便于响应式更新

onBeforeMount(async () => {
  await fetch()
})
async function fetch() {
  try {
    const res = await getRecruit('bmEvM3d6a0ZYd2xocFF6VlFTSy9rdz09OjoC2uPP4sux5wEJErpu9V4g')
    // 处理 data 中的路径，确保正确使用模板字符串
    // data.forEach(item => {
    //   if (item.child) {
    //     item.child.forEach(child => {
    //       if (child.path.includes('${window.location.href}')) {
    //         child.path = child.path.replace('${window.location.href}', window.location.href)
    //       }
    //     })
    //   } else {
    //     if (item.router.includes('${window.location.href}')) {
    //       item.router = item.router.replace('${window.location.href}', window.location.href)
    //     }
    //   }
    // })
    menu.value = JSON.parse(res.data.data.data) // 更新 menu 的值
    // console.log(menu.value,1123)
  } catch (error) {
    console.error('Error fetching menu data:', error)
  }
}
const selectMenu = {
  color: '#00ab7a'
}
// const isShowBanner = ref(true) // 控制展示banner
// onMounted(() => {
//   showBanner()
// })

// function showBanner() {
//   //10s后隐藏banner
//   setTimeout(() => {
//     isShowBanner.value = false
//   }, 10000)
// }
// async function openBanner() {
//   isShowBanner.value = true
//   await showBanner()
// }
// function closeBanner() {
//   // isShowBanner.value = false
// }
// 优化windos滚动条
function toScroll() {
  isOpenScroll.value = true
  let timer
  timer && clearTimeout(timer)
  timer = setTimeout(() => {
    isOpenScroll.value = false
  }, 500)
}

function toActive(path) {
  window.open(path)
}

function toJumpMenu(item) {
  const { key, router } = item
  if (router) {
    current.value[0] = 'home'
    window.open(router)
  } else if (key === 'about') {
    emit('toJumpKey', { id: 9 })
  }
}
// 接收父组件传递过来的方法
const emit = defineEmits(['toJumpKey'])

const handelClick = function (newKey) {
  if(newKey.router!='/'){
    window.open(newKey.router)
  }else{
    //调用父组件传递过来的方法，传入参数修改父组件的值
    emit('toJumpKey', newKey)
  }
}
</script>
<template>
  <div class="header">
    <div class="headerLeft">
      <img :src="logo" alt="logo" />
      <a-menu :selectedKeys="current" mode="horizontal">
        <div v-for="item in menu" :key="item.key" class="headerMenuItem">
          <a-menu-item :key="item.key" style="padding: 0;">
            <img v-if="item.icon" :src="item.icon" alt="" class="headerMenuItemIcon" />
            <a
              v-if="!item.child"
              :to="item.router"
              class="headerMenuItemRouter"
              @click="toJumpMenu(item)"
              :style="current[0] === item.key ? selectMenu : ''"
            >
              {{ item.title }}
            </a>
            <a-dropdown v-else placement="bottom">
              <a
                class="headerMenuItemRouter"
                :class="item.key === 'active' ? 'tr' : ''"
                :style="current[0] === item.key ? selectMenu : ''"
                >{{ item.title }}</a
              >
              <template #overlay>
                <a-menu v-if="item.key === 'core'" class="headerMenuItemDrop">
                  <a-menu-item v-for="i in item.child" :key="i.key">
                    <a @click="handelClick(i)">{{ i.title }}</a>
                  </a-menu-item>
                </a-menu>
                <a-menu
                  v-else-if="item.key === 'active'"
                  class="headerMenuItemDropActive"
                  :class="isOpenScroll ? 'isopenscroll' : ''"
                  @scroll="toScroll"
                >
                  <img
                    class="headerMenuItemDropActiveImg"
                    v-for="i in item.child"
                    :key="i.key"
                    :src="i.img"
                    alt=""
                    @click="toActive(i.path)"
                  />
                </a-menu>
              </template>
            </a-dropdown>
          </a-menu-item>
        </div>
      </a-menu> 
    </div>
    <!-- <div class="headerData">
      <div class="headerData_2023">
        <img
          class="headerData_2023Img"
          src="https://p4.ssl.qhimg.com/t011c23376b2adbbc76.png"
          alt="2023官网入口"
          @click="toActive(ISC_URL)"
          @mouseenter="openBanner()"
          @mouseleave="closeBanner()"
        />
      </div>
    </div> -->
    <!-- <div v-if="isShowBanner" class="headerBanner">
      <img
        class="headerBannerImg"
        src="https://p2.ssl.qhimg.com/t01bfd4ca65cf9aff2e.jpg"
        alt="内容图"
        @click="toActive(ISC_URL)"
      />
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
ul {
  border: 0;
}
.tr {
  color: transparent !important;
}
.menu {
  top: 40px;
}
.header {
  min-width: 1470px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  padding: 8px 120px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 #0000001a;
  z-index: 20;
  &Banner {
    position: absolute;
    left: 0;
    top: 64px;
    text-align: center;
    width: 100%;
    height: 96px;
    background-color: #020629;
    cursor: pointer;
    img {
      height: 100%;
    }
  }
  &Left {
    display: flex;
    img {
      margin-right: 20px;
    }
  }
  &MenuItem {
    display: inline-block;
    position: relative;
    min-width: 104px;
    &Icon {
      position: absolute;
      top: -7px;
      left: -16px;
      width: 96px;
      margin-right: 0px !important;
    }
    &Router {
      font-size: 16px;
      color: #222222;
    }
    &Router:hover {
      color: #00ab7a;
    }
    &Drop {
      display: flex;
      flex-wrap: wrap;
      top: 25px;
      width: 134px;
      height: 248px;
      padding: 25px 35px;
      box-shadow: 0 2px 4px 0 #0000001a;
      border-radius: 8px;

      a {
        font-size: 16px;
        color: #222222;
      }
      a:hover {
        color: #00ab7a;
      }
    }
    &DropActive {
      display: flex;
      width: 100vw;
      min-width: 1470px;
      overflow-x: scroll;
      top: 20px;
      padding: 24px 120px;
      background: #f3f4f7;
      box-shadow: 0 2px 4px 0 #0000001a;
      img {
        width: 590px;
        height: 200px;
        cursor: pointer;
        margin-right: 21px;
      }
    }
  }
  &Data {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 20px;
    &_2023Img {
      width: 171px;
      height: 32px;
      cursor: pointer;
    }
  }
  &Phone {
    width: 193px;
    height: 32px;
    line-height: 30px;
    background: linear-gradient(-90deg, #00ab7a 0%, #0096a6 36%, #3352e2 100%);
    border-radius: 2px;
    padding: 0 12px;
    cursor: pointer;
    img {
      width: 35px;
      height: 16px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      padding-right: 15px;
    }
    span {
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      margin-left: 20px;
    }
  }
  &Search {
    width: 24px;
    height: 24px;
    margin: 0 24px;
    cursor: pointer;
  }
  &Login {
    width: 70px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #00ab7a;
    border-radius: 2px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
  }
}
:deep(.ant-menu-item:active) {
  background: transparent;
}
:deep(.ant-menu-submenu-title:active) {
  background: transparent;
}
:deep(.ant-dropdown-menu-item:hover) {
  background: transparent;
}
:deep(.ant-dropdown .ant-dropdown-menu) {
  top: 4px;
  width: 134px;
}
:deep(.ant-dropdown-menu-item) {
  padding: 0;
}
:deep(.ant-menu-item) {
  text-align: center;
}

// 解决windows滚动条（美化weindows滚动条成macos）
ul {
  margin: 0;
  overflow: overlay;
  transition: 0.3s background-color;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border-radius: 12px;
  background-clip: content-box;
  border: 2px solid transparent;
}

.isopenscroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
