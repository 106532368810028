<script setup>
import Header from '@/components/common/mobile-header.vue'
import HomePage from './mobile-page.vue'
import HomeCore from './mobile-core.vue'
import HomeWander from './mobile-wander.vue'
import HomeISC from './mobile-isc.vue'
import HomeAbout from './mobile-about.vue'
import { ref, onMounted } from 'vue'

import initShare from '@/utils/wxShare.js'

initShare({
  title: 'ISC —— 为数字安全赋能',
  desc: '为数字安全赋能的全栈式营销服务商',
  imgUrl: 'https://p1.ssl.qhimg.com/t01c773b371e5596287.png',
  link: window.location.href
})

onMounted(() => {
  // 显示弹窗
  setTimeout(()=>{
    opacityBtn.value = 1;
  }, 1000)
  // 延时关闭弹窗
  setTimeout(()=>{
    opacityBtn.value = 0;
    setTimeout(() => {
      showDialog.value = false
    }, 1000);
  }, 6000)
})

//是否开启弹窗
//const showDialog = ref(true)
const showDialog = ref(false)
const opacityBtn = ref(0)

// 关闭弹窗
const closeDialog = () => {
  opacityBtn.value = 0;
  setTimeout(() => {
    showDialog.value = false
  }, 1000);
}
// 跳转到2024官网
const jumpNewweb = () => {
  const baseUrl=window.location.origin
  window.open(`${baseUrl}/new/2024.html`, '_blank')
}
</script>
<template>
  <Header />
  <HomePage />
  <HomeCore />
  <HomeWander />
  <HomeISC />
  <HomeAbout />
  <div v-show="showDialog" class="dialog" :style="{ opacity: opacityBtn }">
      <img class="dialogHeader" @click="closeDialog" src="https://p5.ssl.qhimg.com/t110b9a93016f684a3e2097d5fb.jpg" alt="">
      <img @click="jumpNewweb" src="https://p4.ssl.qhimg.com/t110b9a9301034501274db2a44d.png" alt="" class="dialogImg">
    </div>
</template>
<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .5);
  z-index: 99;
  transition: opacity 2s ease;
  &Header {
    position: absolute;
    top: 18%;
    left: 90%;
    transform: translate(-50%, -50%);
  }
  &Img{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
  }
}
</style>
