<script setup>
import {onBeforeMount, ref} from 'vue'
import { Active } from '@/mock/header.js'
import {getRecruit} from '@/api/2023/index'
const openMenu = ref(false) // 打开菜单
const openMenuChild = ref(true) //打开子菜单
const openActive = ref(false) // 是否打开活动页
const props = defineProps(['current'])
const current = ref(props.current || 'home')
const menu = ref([]) // 将 menu 定义为 ref，以便于响应式更新

onBeforeMount(async () => {
  await fetch()
})
//这个要改成接口2
const activeArr = ref([]) // 将 activeArr 定义为 ref，以便于响应式更新
async function fetch() {
  try {
    const res = await getRecruit('ZFVDc25CUmg1N09hVmY3d0xZVmZUdz09OjqMrRuWwW4JC44A546y0dXz')
    // 处理 data 中的路径，确保正确使用模板字符串
    // data.forEach(item => {
    //   if (item.child) {
    //     item.child.forEach(child => {
    //       if (child.path.includes('${window.location.href}')) {
    //         child.path = child.path.replace('${window.location.href}', window.location.href)
    //       }
    //     })
    //   } else {
    //     if (item.router.includes('${window.location.href}')) {
    //       item.router = item.router.replace('${window.location.href}', window.location.href)
    //     }
    //   }
    // })
    const data = JSON.parse(res.data.data.data)
    menu.value = data.menu
    activeArr.value = data.activeArr
  } catch (error) {
    console.error('Error fetching menu data:', error)
  }
}
function toOpen() {
  openMenu.value = !openMenu.value
}
function toOpenChild() {
  openMenuChild.value = !openMenuChild.value
}
function toRouter({ key, router }) {
  if (key === 'about') {
    // 滚动到底部
    window.scrollTo(0, document.body.scrollHeight)
  } else if (key === 'active') {
    openActive.value = !openActive.value // 打开活动页
  } else {
    window.location.href = router
  }
  openMenu.value = false
}
function toNewPage(path) {
  window.open(path) // 跳转至活动列表
  openActive.value = false // 关闭活动页
}
</script>
<template>
  <div class="header">
    <div class="headerNav">
      <img class="headerLogo" src="https://p0.ssl.qhimg.com/t01b2020a12f33ce4c4.png" alt="" />
<!--      手机端最新活动-->
      <p class="headerPhone" v-if="activeArr">
        <!-- <img
          src="https://p1.ssl.qhimg.com/t014d2c86b480680f2a.png"
          alt="2023官网入口"
          @click="toNewPage(ISC_URL)"
        /> -->
        <img
          src="https://p2.ssl.qhimg.com/t01438d6646331b6ece.png"
          alt="最新活动"
          @click="toRouter(Active)"
        />
      </p>
    </div>
    <img
      v-if="!openMenu"
      class="headerMore"
      src="https://p5.ssl.qhimg.com/t01a99dc825acdb9dff.png"
      alt=""
      @click="toOpen"
    />
    <img
      v-else
      class="headerMore"
      src="https://p5.ssl.qhimg.com/t01127f59076ae828f2.png"
      alt=""
      @click="toOpen"
    />
  </div>
  <!-- <img
    class="headerBanner"
    src="https://p2.ssl.qhimg.com/t01006b50fc03a8368e.png"
    alt="banner"
    @click="toNewPage(ISC_URL)"
  /> -->
  <ul v-if="openMenu" class="menu">
    <li
      v-for="item in menu"
      :key="item.key"
      class="menuList"
      :class="[
        current === item.key ? 'pitch' : '',
        item.child && openMenuChild ? 'menuChildOpen' : ''
      ]"
      @click="toRouter(item)"
    >
      <img v-if="item.icon" :src="item.icon" alt="" class="menuListIcon" />
      {{ item.title }}
      <img
        v-if="item.child"
        class="menuDown"
        :class="!openMenuChild ? 'rotato' : ''"
        src="https://p1.ssl.qhimg.com/t012561152d4c326316.png"
        @click="toOpenChild"
      />
      <ul v-if="item.child && openMenuChild" class="menuListChild">
        <li class="menuListChildList" v-for="i in item.child" :key="i.key">{{ i.title }}</li>
      </ul>
    </li>
  </ul>
  <div v-if="openActive" class="active">
    <img
      v-for="item in activeArr"
      :key="item"
      :src="item.src"
      alt="活动图"
      class="activeImg"
      @click="toNewPage(item.path)"
    />
  </div>
</template>
<style lang="scss" scoped>
.active {
  width: 100%;
  min-height: calc(100vh - 52px);
  background: #e9f1ff;
  padding: 12px;
  // overflow-y: scroll;
  &Img {
    display: block;
    width: 100%;
    height: 266px;
    // border: 1px solid red;
    border-radius: 8px;
    margin: 12px auto 12px;
  }
}
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
  height: 48px;
  // padding: 12px 14px 10px 16px;
  padding: 12px 14px 10px 0px;
  &Nav {
    left: 16px;
    top: 0px;
  }
  &Logo {
    width: 26px;
    margin-right: 16px;
  }
  &Phone {
    display: inline-block;
    width: 84px;
    height: 28px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &Banner {
    width: 100vw;
  }
  &More {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-top: 3px;
  }
}
.menu {
  position: absolute;
  z-index: 999;
  background: #f7f7f7;
  padding: 0 16px;
  width: 100vw;
  &List {
    list-style: none;
    font-size: 14px;
    color: #222222;
    padding: 10px 0 10px 8px;
    border-bottom: 1px solid #d8d8d8;
    &:last-child {
      border: 0;
    }
    &Icon {
      width: 15px;
      margin: 0 3px 3px 0;
    }
  }
  &Down {
    width: 14px;
    float: right;
    margin-right: 4px;
  }
  &ListChild {
    border-top: 1px solid #d8d8d8;
    margin-top: 10px;
    &List {
      list-style: none;
      font-size: 14px;
      color: #222222;
      padding: 10px 0 10px 15px;
      border-bottom: 1px solid #d8d8d8;
    }
  }
  &ChildOpen {
    border: 0;
    padding-bottom: 0;
  }
}
.pitch {
  color: #00ab7a;
}
.rotato {
  transform: rotate(-90deg);
}
</style>
