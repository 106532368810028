<script setup>
import { ref, onBeforeMount } from 'vue'
import { iscData } from '@/mock/index'
import { getKeyGuest } from '@/api/2023/index.js'
const guestArr = ref([])
let guestArrPage = ref(null)
const guestPage = ref([])
const tagNum = ref(0)
function toPitch(index) {
  tagNum.value = index
  guestPage.value = pagination(tagNum.value + 1, 10, guestArr.value)
}
function toMore() {
  const path = `${window.location.origin}/new/2023/guest.html`
  window.open(path)
}
function pagination(pageNo, pageSize, array) {
  var offset = (pageNo - 1) * pageSize
  return offset + pageSize >= array.length
    ? array.slice(offset, array.length)
    : array.slice(offset, offset + pageSize)
}

const getInfo = () => {
  getKeyGuest()
    .then((res) => {
      guestArr.value = res.data.data
      guestArrPage.value = Math.round(guestArr.value.length / 10)
      guestPage.value = pagination(1, 10, guestArr.value)
    })
    .catch(() => {})
}

onBeforeMount(async () => {
  await getInfo()
})
</script>
<template>
  <div class="isc">
    <img class="iscVideo" :src="iscData.bgc" alt="背景" />
    <!-- <video class="iscVideo" autoplay muted loop>
      <source
        src="https://isc.360kan.com/vod-isc-vod-101240-beijing/TLc6cb7a02328c3f4cc0164dd03a50cb66.mp4"
        type="video/mp4"
      />
      您的浏览器不支持 video 标签。
    </video> -->
    <div class="wrap">
      <p class="iscTitle">ISC智库</p>
      <ul class="iscNav">
        <li v-for="(item, index) in guestPage" :key="index" class="iscNavList">
          <img :src="item.avatar" alt="头像" />
          <p class="title">{{ item.name }}</p>
          <p class="desc">
            <span v-for="(i, j) in item.title" :key="j">
              {{ i }}
              <span v-if="j !== item.title.length - 1">,</span>
            </span>
          </p>
        </li>
      </ul>
      <ul class="iscDots">
        <li
          v-for="(item, index) in guestArrPage"
          :key="item"
          :class="tagNum === index ? 'pitch' : ''"
          @click="toPitch(index)"
        ></li>
      </ul>
      <div class="iscMore" @click="toMore">查看更多</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.isc {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  // background: url('https://p1.ssl.qhimg.com/t016f31c171a38d9b07.jpg') 100% 100%;
  text-align: center;
  padding-top: 46px;
  .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &Video {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &Dots {
    margin-top: 38px;
    margin-bottom: 20px;
    li {
      cursor: pointer;
      display: inline-block;
      list-style: none;
      width: 8px;
      height: 8px;
      background: #bdc4ca;
      border-radius: 50%;
      margin-right: 8px;
    }
    .pitch {
      background-color: #00ab7a;
    }
  }
  &Title {
    // width: 206px;
    height: 46px;
    background: url('https://s0.ssl.qhres2.com/static/bd72b716524545b6.svg') no-repeat center;
    margin: 0 0 64px;
    line-height: 46px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
  }
  &Nav {
    width: 1196px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    &List {
      display: inline-block;
      width: 180px;
      height: 212px;
      text-align: center;
      list-style: none;
      margin: 0 10px 32px;
      img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin: auto;
        background-color: #fff;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        color: #222222;
        margin: 14px 0 6px;
      }
      .desc {
        font-size: 12px;
        color: #222222;
      }
    }
  }
  &More {
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: 32px;
    width: 110px;
    height: 36px;
    line-height: 36px;
    background: #00ab7a;
    border-radius: 2px;
    margin-top: 66px;
    margin: 0 auto;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
