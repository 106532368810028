import { createApp } from 'vue'
import App from '@/page/vue/index.vue'
import tongji from '@/utils/tongji.js'; //引入统计
import Antd from 'ant-design-vue'
import Vant from 'vant'
import 'ant-design-vue/dist/antd.css'

import 'animate.css'
import '@/styles/index.scss'
import 'vant/lib/index.css'

import Qapm from '@q/qapm';
Qapm('init', {
    appKey: 'isc',
    ratio: 100,
});

tongji(); //统计

const app = createApp(App)
app.use(Antd)
app.use(Vant)

app.mount('#app')
