<script setup>
import { ref, onMounted } from 'vue'
const videoRef = ref(null)
onMounted(() => {
  document.addEventListener(
    'WeixinJSBridgeReady',
    function () {
      videoRef.value.play()
    },
    false
  )
})
</script>
<template>
  <div class="home">
    <!-- <video class="homeVideo" ref="videoRef" autoplay muted loop>
      <source
        src="https://isc.360kan.com/vod-isc-vod-101240-beijing/TL6e29f179d9705459de9bb428b944c1f0.mp4"
        type="video/mp4"
      />
      您的浏览器不支持 video 标签。
    </video> -->
    <img class="homeVideo" src="https://p2.ssl.qhimg.com/t01546e91be3b642afc.png" alt="" />
    <div class="homeMask"></div>
    <!-- <img class="homeBtn" :src="btnImg" alt="" @click="toVideo" /> -->
    <div class="homeTitle">
      <p>为数字安全行业赋能</p>
      <p>EMPOWER THE DIGITAL SECURITY INDUSTRY</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.home {
  position: relative;
  height: 400px;
  overflow: hidden;
  text-align: center;
  &Btn {
    position: absolute;
    top: 180px;
    left: 50%;
    transform: translateX(-50%);
  }
  &Mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000033;
  }
  &Title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    p:first-child {
      font-family: TypeRegular;
      font-weight: bold;
      font-size: 32px;
      color: #ffffff;
    }
    p:last-child {
      font-family: TypeRegular;
      font-weight: bold;
      font-size: 11px;
      color: #ffffff;
    }
  }
  &Video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100vw;
    height: 100%;
  }
}
</style>
