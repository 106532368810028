<script setup>
import iscPartner from '@/api/isc-partner.json'
import { iscData } from '@/mock/index'
import { ref } from 'vue'
const partnerArr = [
  {
    key: 'company',
    title: '厂商'
  },
  {
    key: 'institution',
    title: '机构'
  },
  {
    key: 'media',
    title: '媒体'
  }
]
const tagPartner = ref(partnerArr[0].key)
function toTag(key) {
  tagPartner.value = key
}
</script>
<template>
  <div class="partner">
    <img class="partnerVideo" :src="iscData.bgc" alt="" />
    <!-- <video class="partnerVideo" autoplay muted loop>
      <source
        src="https://isc.360kan.com/vod-isc-vod-101240-beijing/TLc6cb7a02328c3f4cc0164dd03a50cb66.mp4"
        type="video/mp4"
      />
      您的浏览器不支持 video 标签。
    </video> -->
    <div class="wrap">
      <p class="partnerTitle">合作伙伴</p>
      <div class="partnerNav">
        <ul class="partnerNavLeft">
          <li
            v-for="item in partnerArr"
            :key="item.key"
            class="partnerNavLeftList"
            :class="tagPartner === item.key ? 'checked' : 'nochecked'"
            @click="toTag(item.key)"
          >
            {{ item.title }}
          </li>
        </ul>
        <div class="partnerNavRight">
          <p
            v-for="(item, index) in iscPartner[tagPartner]"
            :key="index"
            class="partnerNavRightImg"
          >
            <img :src="item.logo" :alt="item.name" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.partner {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  background: url('https://p1.ssl.qhimg.com/t016f31c171a38d9b07.jpg') 100% 100%;
  text-align: center;
  padding-top: 46px;
  .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &Video {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &Title {
    // width: 206px;
    height: 46px;
    background: url('https://s0.ssl.qhres2.com/static/bd72b716524545b6.svg') no-repeat center;
    margin: 0 0 64px;
    line-height: 46px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
  }
  &Nav {
    display: flex;
    &LeftList {
      cursor: pointer;
      width: 266px;
      font-weight: bold;
      font-size: 28px;
      padding: 58px 40px;
      text-align: left;
      list-style: none;
      margin: 0 32px 22px 0;
    }
    .checked {
      color: #ffffff;
      background: url('https://p1.ssl.qhimg.com/t01e42795213b8b0208.png') no-repeat;
      background-size: 100% 100%;
    }
    .nochecked {
      color: rgba(34, 34, 34, 0.6);
      background: url('https://p1.ssl.qhimg.com/t01decc885a706b8072.png') no-repeat;
      background-size: 100% 100%;
    }
    &Right {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      flex-direction: column;
      // align-items: center;
      width: 904px;
      height: 600px;
      overflow-x: scroll;
      padding-bottom: 26px;
      // border: 1px solid red;
      &Img {
        position: relative;
        padding: 8px;
        width: 111px;
        height: 111px;
        margin-bottom: 20px;
        margin-right: 20px;
        background-color: #fff;
      }
      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 95px;
        // height: 95px;
      }
    }
  }
}
</style>
